


















































































































































































import Vue, { PropType } from "vue";
import { CrudEnum } from "@/interfaces/shared/view_profile/view_profile_crud";
import { mapActions, mapGetters, mapMutations } from "vuex";
import {
  AUTH_LOADING,
  UPDATE_USER_PROFILE,
  USER_PROFILE
} from "@/store/modules/auth/constants";
import { Candidates } from "@/interfaces/data_objects/candidates";
import { ROOT_ERROR, ROOT_NOTIFICATION } from "@/store/modules/root/constants";
import DatePicker from "@/components/shared/view_profile/DatePicker.vue";
import { clean_string } from "@/utils/global";
import moment from "moment/moment";
import { scoreUtils } from "@/utils";

export default Vue.extend({
  name: "CrudWorkDetails",
  components: { DatePicker },
  data() {
    return {
      job_title: "",
      organization: "",
      start_date: "",
      end_date: "",
      details: "",
      work_form: false,
      currently_working: false,
      required_rule: [(value: string) => !!value || "Field is required"],
      future_dates_not_allowed: [
        (value: string) => {
          return (value &&
            moment(value).month() > moment().month() &&
            moment(value).year() >= moment().year()) ||
            (moment(value).month() < moment().month() &&
              moment(value).year() > moment().year())
            ? "Future Dates not allowed"
            : true;
        }
      ],
      start_dates_not_allowed: [] as ((value: string) => boolean | string)[]
    };
  },
  props: {
    type: {
      type: String as PropType<CrudEnum>,
      required: true
    },
    data: {
      type: Object as PropType<Candidates.WorkHistoryDetails>
    },
    index: {
      type: String
    }
  },
  created() {
    if (this.type !== CrudEnum.ADD && this.index !== "-1") {
      this.job_title = this.data?.job_title;
      this.organization = this.data?.organization;
      const time = this.data?.time_period.split("-");
      this.start_date = time[0];
      this.end_date = clean_string(time[1]) === "present" ? "" : time[1];
      this.details = this.data?.details;
      this.currently_working = !this.end_date;
    }
    this.start_dates_not_allowed = [
      (value: string) => {
        return (value &&
          this.start_date &&
          moment(value).month() <= moment(this.start_date, "MM/YYYY").month() &&
          moment(value).year() <= moment(this.start_date, "MM/YYYY").year()) ||
          (moment(value).month() > moment(this.start_date, "MM/YYYY").month() &&
            moment(value).year() < moment(this.start_date, "MM/YYYY").year())
          ? "End date should be greater than start date"
          : true;
      }
    ];
  },
  computed: {
    CrudEnum() {
      return CrudEnum;
    },
    ...mapGetters("auth", {
      user_profile: USER_PROFILE,
      auth_loading: AUTH_LOADING
    })
  },
  methods: {
    ...mapActions("auth", {
      update_user_profile: UPDATE_USER_PROFILE
    }),
    ...mapMutations({
      root_error: ROOT_ERROR,
      root_notification: ROOT_NOTIFICATION
    }),
    select_start_date(date: string) {
      this.start_date = date;
    },
    select_end_date(date: string) {
      this.end_date = date;
    },
    cancel_action() {
      this.job_title = "";
      this.organization = "";
      this.start_date = "";
      this.end_date = "";
      this.details = "";
      this.work_form = false;
      this.$emit("cancel", false);
    },
    async update_details() {
      const payload = this.user_profile;
      let time_period = this.start_date;
      if (this.currently_working) time_period += ` - Present`;
      else if (this.end_date) time_period += ` - ${this.end_date}`;
      delete payload.profile.career_path;
      const data_obj = {
        job_title: this.job_title,
        organization: this.organization,
        time_period: time_period,
        details: this.details
      };
      if (this.type === CrudEnum.UPDATE) {
        payload.profile.work_history_detailed[this.index] = data_obj;
      } else if (this.type === CrudEnum.ADD) {
        // Add new data at first index and incrementing other keys by 1
        payload.profile.work_history_detailed = {
          "1": data_obj,
          ...Object.fromEntries(
            Object.entries(payload.profile.work_history_detailed).map(
              ([k, v]) => [parseInt(k) + 1, v]
            )
          )
        };
      } else if (this.type === CrudEnum.DELETE) {
        delete payload.profile.work_history_detailed[this.index];
        // Decrementing all keys by 1
        payload.profile.work_history_detailed = Object.fromEntries(
          Object.entries(payload.profile.work_history_detailed).map(
            ([k, v]) => {
              const newKey =
                parseInt(k) > parseInt(this.index)
                  ? parseInt(k) - 1
                  : parseInt(k);
              return [newKey, v];
            }
          )
        );
      }
      const response = await this.update_user_profile(payload);
      if (response) {
        this.root_notification("Profile Updated Successfully");
      } else this.root_error("Failed to update profile");
      scoreUtils.clearProfileScore();
      this.$emit("cancel", false);
      this.job_title = "";
      this.organization = "";
      this.start_date = "";
      this.end_date = "";
      this.details = "";
      this.work_form = false;
    }
  }
});
