
































































































































































































import Vue, { PropType } from "vue";
import { CrudEnum } from "@/interfaces/shared/view_profile/view_profile_crud";
import { mapActions, mapGetters, mapMutations } from "vuex";
import {
  AUTH_LOADING,
  UPDATE_USER_PROFILE,
  USER_PROFILE
} from "@/store/modules/auth/constants";
import { Candidates } from "@/interfaces/data_objects/candidates";
import { ROOT_ERROR, ROOT_NOTIFICATION } from "@/store/modules/root/constants";
import DatePicker from "@/components/shared/view_profile/DatePicker.vue";
import moment from "moment/moment";
import { scoreUtils } from "@/utils";

export default Vue.extend({
  name: "CrudCertificationDetails",
  components: { DatePicker },
  data() {
    return {
      name: "",
      organization_name: "",
      issue_date: "",
      expiration_date: "",
      credential_id: "",
      credential_url: "",
      details: "",
      no_expiration: false,
      cert_form: false,
      required_rule: [(value: string) => !!value || "Field is required"],
      future_dates_not_allowed: [
        (value: string) => {
          return (value &&
            moment(value).month() > moment().month() &&
            moment(value).year() >= moment().year()) ||
            (moment(value).month() < moment().month() &&
              moment(value).year() > moment().year())
            ? "Future Dates not allowed"
            : true;
        }
      ],
      start_dates_not_allowed: [] as ((value: string) => boolean | string)[]
    };
  },
  props: {
    type: {
      type: String as PropType<CrudEnum>,
      required: true
    },
    data: {
      type: Object as PropType<Candidates.CertificationDetails>
    },
    index: {
      type: String
    }
  },
  created() {
    if (this.type !== CrudEnum.ADD) {
      this.name = this.data?.name;
      this.organization_name = this.data?.organization_name;
      this.issue_date = this.data?.issue_date;
      this.expiration_date = this.data?.expiration_date;
      this.credential_id = this.data?.credential_id;
      this.details = this.data?.details;
      this.no_expiration = !this.expiration_date;
    }
    this.start_dates_not_allowed = [
      (value: string) => !!value || "Field is required",
      (value: string) => {
        return (value &&
          this.issue_date &&
          moment(value).month() <= moment(this.issue_date, "MM/YYYY").month() &&
          moment(value).year() <= moment(this.issue_date, "MM/YYYY").year()) ||
          (moment(value).month() > moment(this.issue_date, "MM/YYYY").month() &&
            moment(value).year() < moment(this.issue_date, "MM/YYYY").year())
          ? "Expiration date should be greater than Issue date"
          : true;
      }
    ];
  },
  watch: {
    no_expiration(n) {
      if (n) this.start_dates_not_allowed = [];
      else
        this.start_dates_not_allowed = [
          (value: string) => !!value || "Field is required",
          (value: string) => {
            return (value &&
              this.issue_date &&
              moment(value).month() <=
                moment(this.issue_date, "MM/YYYY").month() &&
              moment(value).year() <=
                moment(this.issue_date, "MM/YYYY").year()) ||
              (moment(value).month() >
                moment(this.issue_date, "MM/YYYY").month() &&
                moment(value).year() <
                  moment(this.issue_date, "MM/YYYY").year())
              ? "Expiration date should be greater than Issue date"
              : true;
          }
        ];
    }
  },
  computed: {
    CrudEnum() {
      return CrudEnum;
    },
    ...mapGetters("auth", {
      user_profile: USER_PROFILE,
      auth_loading: AUTH_LOADING
    })
  },
  methods: {
    ...mapActions("auth", {
      update_user_profile: UPDATE_USER_PROFILE
    }),
    ...mapMutations({
      root_error: ROOT_ERROR,
      root_notification: ROOT_NOTIFICATION
    }),
    select_issue_date(date: string) {
      this.issue_date = date;
    },
    select_expiration_date(date: string) {
      this.expiration_date = date;
    },
    cancel_action() {
      this.$emit("cancel", false);
    },
    async update_details() {
      const payload = this.user_profile;
      const data_obj = {
        name: this.name,
        organization_name: this.organization_name,
        issue_date: this.issue_date,
        expiration_date: this.no_expiration ? "" : this.expiration_date,
        credential_id: this.credential_id,
        details: this.details
      };
      if (this.type === CrudEnum.UPDATE) {
        payload.profile.certification_detailed[this.index] = data_obj;
      } else if (this.type === CrudEnum.ADD) {
        if (!payload.profile.certification_detailed) {
          payload.profile.certification_detailed = {
            "1": data_obj
          };
        } else {
          // Add new data at first index and incrementing other keys by 1
          payload.profile.certification_detailed = {
            "1": data_obj,
            ...Object.fromEntries(
              Object.entries(payload.profile.certification_detailed).map(
                ([k, v]) => [parseInt(k) + 1, v]
              )
            )
          };
        }
      } else if (this.type === CrudEnum.DELETE) {
        delete payload.profile.certification_detailed[this.index];
        // Decrementing all keys by 1
        payload.profile.certification_detailed = Object.fromEntries(
          Object.entries(payload.profile.certification_detailed).map(
            ([k, v]) => {
              const newKey =
                parseInt(k) > parseInt(this.index)
                  ? parseInt(k) - 1
                  : parseInt(k);
              return [newKey, v];
            }
          )
        );
      }
      const response = await this.update_user_profile(payload);
      if (response) {
        this.root_notification("Profile Updated Successfully");
      } else this.root_error("Failed to update profile");
      scoreUtils.clearProfileScore();
      this.cert_form = false;
      this.cancel_action();
    }
  }
});
