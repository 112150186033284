

























































































































































import Vue, { PropType } from "vue";
import { CrudEnum } from "@/interfaces/shared/view_profile/view_profile_crud";
import { mapActions, mapGetters, mapMutations } from "vuex";
import {
  AUTH_LOADING,
  UPLOAD_HIGHLIGHT_AWARDS,
  USER_PROFILE
} from "@/store/modules/auth/constants";
import {
  UploadedFile,
  ValidateFileType
} from "@/interfaces/shared/file_validation";
import { get_file_size, validate_file_type } from "@/utils/global";
import { ROOT_NOTIFICATION } from "@/store/modules/root/constants";
import { scoreUtils } from "@/utils";

export default Vue.extend({
  name: "CrudHighlightsAwards",
  data() {
    return {
      awards_form: false,
      job_files: [],
      selected_files: [] as UploadedFile[],
      drop_file_image: require("@/assets/illustrations/file.svg"),
      error: false as boolean,
      error_message: "" as string,
      file_id: 1 as number,
      is_valid: false as boolean
    };
  },
  computed: {
    CrudEnum() {
      return CrudEnum;
    },
    ...mapGetters("auth", {
      user_profile: USER_PROFILE,
      auth_loading: AUTH_LOADING
    })
  },
  props: {
    type: {
      type: String as PropType<CrudEnum>,
      required: true
    }
  },
  methods: {
    ...mapMutations({
      root_notification: ROOT_NOTIFICATION
    }),
    ...mapActions("auth", {
      upload_awards: UPLOAD_HIGHLIGHT_AWARDS
    }),
    get_file_size,
    cancel_action() {
      this.$emit("cancel", false);
    },
    /**
     * Process user selected files, through drag & drop
     * @param value
     */
    process_dropped_files(value: DragEvent) {
      if (value && value.dataTransfer) {
        const files: File[] = Array.from<File>(value.dataTransfer.files);
        this.validate_file(files);
      }
    },
    /**
     * Process user selected files, through file uploader
     * @param files
     */
    process_selected_files(files: File[]) {
      this.validate_file(files);
    },
    /**
     * Validate selected file type & size: .docs, .pdf & .txt are valid types
     * @return boolean
     * @param files
     */
    validate_file(files: File[]) {
      for (let file of files) {
        const valid_file = this.validate_individual_file(file);
        const obj: UploadedFile = {
          file,
          error: !valid_file.valid,
          error_message: valid_file.msg,
          id: this.file_id
        };
        this.selected_files.push(obj);
        this.file_id += 1;
      }
      this.valid_files();
    },
    valid_files() {
      this.is_valid = true;
      for (let file of this.selected_files) {
        if (file.error) {
          this.is_valid = false;
          break;
        }
      }
    },
    /**
     * Validate selected file type: .docs, .pdf & .txt are valid types
     * @return boolean
     * @param file
     */
    validate_individual_file(file: File): ValidateFileType {
      if (file.size > 2e6) {
        return {
          valid: false,
          msg: "File size should be less than 2 MB!"
        };
      } else if (validate_file_type("img", file)) {
        return {
          valid: true,
          msg: ""
        };
      } else {
        return {
          valid: false,
          msg: ".png, .svg, .jpg files are supported (Max. file size is 2 mb)"
        };
      }
    },
    remove_file(file: UploadedFile) {
      this.selected_files = this.selected_files.filter(
        (_file) => _file.id !== file.id
      );
      this.valid_files();
    },
    async submit_form() {
      const form = new FormData();
      this.selected_files.forEach((file: UploadedFile) =>
        form.append("media", file.file)
      );
      form.append("uid", this.user_profile.info.file_id);
      const response = await this.upload_awards(form);
      if (response) {
        this.root_notification("Highlights & Awards Added successfully");
        this.cancel_action();
      }
      scoreUtils.clearProfileScore();
    }
  }
});
